var isCapable = (function(){
  var ua= navigator.userAgent;
  var tem; 
  var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if(/trident/i.test(M[1])){
      tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE '+(tem[1] || '');
  }
  if(M[1]=== 'Chrome'){
      tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
      if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }

  M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);

  if(M[1] == "Safari" && M[2] <= 13){
    return false;
  }

  return true;
})();

if( !isCapable ){

  var link = document.createElement('link');
  link.rel = "stylesheet";
  link.href = '/assets/environmental-dance/css/under-construction.css';
  document.head.appendChild(link);

  //Create the element using the createElement method.
  const myDiv = document.createElement("div");
  myDiv.style = "display:grid;"
  myDiv.innerHTML =
    '<h1>Environmental Dance</h1>\
    <img src="/assets/environmental-dance/img/globe.png" />\
    <span id="content">\
      <p>Sorry. The browser you are using is too old to see this website.</p>\
      <p>Please upgrade your browser.</p>\
    </span>';

  document.body.appendChild(myDiv);

}else{

   let loader = null;

   import("/src/css/index.css").then(( ) => { })

   import('/src/loader/loader').then(( { default : Loader }) => {
      loader = new Loader( );
      loader.display( { backgroundLigthBlack : true } );
   })   

   import('/src/classes/app').then( ( { default : myApp } ) => {

      const app = new myApp();

      app.createScene().then( ( result ) => {

         loader.hide();

      }, ( error ) => {

         console.log("Could not create Scene: "+error);

      })

      window.addEventListener("beforeunload", ( ) => {
         app.dispose();
      }, false);


   }, ( error) => {

      console.log('Could not import App class: '+error);

   })

}
